import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Faq from "../../components/FaqPage/Faq/Faq";
import EasySteps from "../../components/InvestmentPage/EasySteps/EasySteps";

const FaqPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>FAQ & Support</title>
        <meta name="title" content="Find Answers to Your Questions - Atlas Finance FAQ & Support"/>
        <meta property="og:title" content="Find Answers to Your Questions - Atlas Finance FAQ & Support"/>
        <meta property="twitter:title" content="Find Answers to Your Questions - Atlas Finance FAQ & Support"/>

        <meta name="description" content="Get answers to your trading-related questions on our comprehensive FAQ page. For further assistance, our dedicated support team is here to help. Reach out to us for a seamless trading experience." />
        <meta property="og:description" content="Get answers to your trading-related questions on our comprehensive FAQ page. For further assistance, our dedicated support team is here to help. Reach out to us for a seamless trading experience." />
        <meta property="twitter:description" content="Get answers to your trading-related questions on our comprehensive FAQ page. For further assistance, our dedicated support team is here to help. Reach out to us for a seamless trading experience." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Faq />
        <div className="bg-dark">
          <EasySteps />
          <Footer />
        </div>
      </div>
    </HelmetProvider>
  );
};

export default FaqPage;
