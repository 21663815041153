import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/MarginPage/Banner/Banner";
import WhatIs from "../../components/MarginPage/WhatIs/WhatIs";
import HowWorks from "../../components/MarginPage/HowWorks/HowWorks";
import Advantages from "../../components/MarginPage/Advantages/Advantages";
import Faq from "../../components/MarginPage/Faq/Faq";

const MarginPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Margin Trading</title>
        <meta name="title" content="Amplify Your Trading Potential with Margin Trading on Atlas Finance"/>
        <meta property="og:title" content="Amplify Your Trading Potential with Margin Trading on Atlas Finance"/>
        <meta property="twitter:title" content="Amplify Your Trading Potential with Margin Trading on Atlas Finance"/>

        <meta name="description" content="Unlock the power of margin trading on Atlas Finance. Trade with leverage to magnify your potential returns. Learn about margin requirements and trade with confidence." />
        <meta property="og:description" content="Unlock the power of margin trading on Atlas Finance. Trade with leverage to magnify your potential returns. Learn about margin requirements and trade with confidence." />
        <meta property="twitter:description" content="Unlock the power of margin trading on Atlas Finance. Trade with leverage to magnify your potential returns. Learn about margin requirements and trade with confidence." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <WhatIs />
        <HowWorks />
        <Advantages />
        <Faq />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default MarginPage;
