import HomePage from '../pages/HomePage/HomePage';
import InvestmentPage from '../pages/InvestmentPage/InvestmentPage';
import MarginPage from '../pages/MarginPage/MarginPage';
import AccoutsPage from '../pages/AccountsPage/AccountsPage';
import FaqPage from '../pages/FaqPage/FaqPage';
import AboutPage from '../pages/AboutPage/AboutPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/investment', element: InvestmentPage },
    { path: '/margin', element: MarginPage },
    { path: '/accounts', element: AccoutsPage },
    { path: '/faq', element: FaqPage },
    { path: '/about', element: AboutPage },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/investment', element: InvestmentPage },
    { path: '/:lang/margin', element: MarginPage },
    { path: '/:lang/accounts', element: AccoutsPage },
    { path: '/:lang/faq', element: FaqPage },
    { path: '/:lang/about', element: AboutPage },
];

export const headerRoutes = [
    { id: 1, path: 'investment', text: 'header_investment' },
    { id: 2, path: 'margin', text: 'header_margin' },
    { id: 3, path: 'accounts', text: 'header_accounts' },
    { id: 4, path: 'faq', text: 'header_faq' },
    { id: 5, path: 'about', text: 'header_about' },
];
