import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/InvestmentPage/Banner/Banner";
import Bonds from "../../components/InvestmentPage/Bonds/Bonds";
import TypesBonds from "../../components/InvestmentPage/TypesBonds/TypesBonds";
import TradingStocks from "../../components/InvestmentPage/TradingStocks/TradingStocks";
import ExchangeTraded from "../../components/InvestmentPage/ExchangeTraded/ExchangeTraded";
import ReadyTo from "../../components/InvestmentPage/ReadyTo/ReadyTo";
import EasySteps from "../../components/InvestmentPage/EasySteps/EasySteps";

const InvestmentPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Investment Choices</title>
        <meta name="title" content="Diversify Your Portfolio with a Range of Investment Choices on Atlas Finance"/>
        <meta property="og:title" content="Diversify Your Portfolio with a Range of Investment Choices on Atlas Finance"/>
        <meta property="twitter:title" content="Diversify Your Portfolio with a Range of Investment Choices on Atlas Finance"/>

        <meta name="description" content="Explore a wide range of investment options on Atlas Finance. Trade stocks, bonds, forex, ETFs, and options. Diversify your portfolio and maximize your investment potential." />
        <meta property="og:description" content="Explore a wide range of investment options on Atlas Finance. Trade stocks, bonds, forex, ETFs, and options. Diversify your portfolio and maximize your investment potential." />
        <meta property="twitter:description" content="Explore a wide range of investment options on Atlas Finance. Trade stocks, bonds, forex, ETFs, and options. Diversify your portfolio and maximize your investment potential." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Bonds />
        <TypesBonds />
        <TradingStocks />
        <ExchangeTraded />
        <ReadyTo />
        <div className="bg-dark">
          <EasySteps />
          <Footer />
        </div>
      </div>
    </HelmetProvider>
  );
};

export default InvestmentPage;
