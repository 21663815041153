import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import classes from "./Award.module.scss";
import img1 from "../../../assets/img/main/4/1.png";
import { useTranslation } from "react-i18next";

const Award = () => {
    const { t } = useTranslation();
    gsap.registerPlugin(ScrollTrigger);
    gsap.config({
        force3D: true,
    });
    const [width, setWidth] = useState();
    const col1 = useRef(null);
    const monetRef1 = useRef(null);
    useEffect(() => {
        if (width > 1220) {
            gsap.timeline().to(monetRef1.current, {
                scrollTrigger: {
                    trigger: col1.current,
                    start: "top bottom",
                    end: "top center",
                    scrub: 4,
                },
                y: 0
            });
        }
    }, [width]);
    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", (event) => {
            setWidth(window.innerWidth);
        });
    }, []);
    return (
        <div className={[classes.award, "pt"].join(" ")} ref={col1}>
            <div className={[classes.awardBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.awardRow, ""].join(" ")}>
                        <div className={[classes.awardRowLeft, ""].join(" ")}>
                            <h2 className={[classes.awardRowLeftTitle, "font-40"].join(" ")}>
                                {t('home_award_title')}
                            </h2>
                            <p className={[classes.awardRowLeftText, "font-16"].join(" ")}>
                                {t('home_award_text_01')}
                            </p>
                            <p className={[classes.awardRowLeftText, "font-16"].join(" ")}>
                                {t('home_award_text_02')}
                            </p>
                            <p className={[classes.awardRowLeftText, "font-16"].join(" ")}>
                                {t('home_award_text_03')}
                            </p>
                        </div>
                        <div className={[classes.awardRowRight, ""].join(" ")}
                            ref={monetRef1}
                        >
                            <img src={img1} alt="Our Award- winning Platform" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Award