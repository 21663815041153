import React, { useRef, useEffect } from "react";
import classes from './HowWorks.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/MarginPage/HowWorks/image_01.png';
import image_02 from '../../../assets/img/MarginPage/HowWorks/image_02.png';
import image_03 from '../../../assets/img/MarginPage/HowWorks/image_03.png';
import image_04 from '../../../assets/img/MarginPage/HowWorks/image_04.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const HowWorks = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const bodyRef = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            image: image_01,
            title: 'margin_how_card_01_title',
            text: 'margin_how_card_01_text',
        },
        {
            ref: cardRef_02,
            image: image_02,
            title: 'margin_how_card_02_title',
            text: 'margin_how_card_02_text',
        },
        {
            ref: cardRef_03,
            image: image_03,
            title: 'margin_how_card_03_title',
            text: 'margin_how_card_03_text',
        },
        {
            ref: cardRef_04,
            image: image_04,
            title: 'margin_how_card_04_title',
            text: 'margin_how_card_04_text',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 150,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=100px bottom",
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_04.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=100px bottom",
                    end: "+=300px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.howWorks}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t('margin_how_title')}
                </h2>
                <div ref={bodyRef} className={classes.cards}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} key={index} className={classes.card}>
                            <div className={classes.cardImage}>
                                <img src={card.image} alt=''/>
                            </div>
                            <div className={classes.cardContent}>
                                <h4 className={[classes.cardTitle, 'font-20-md6'].join(' ')}>
                                    {t(card.title)}
                                </h4>
                                <p className={[classes.cardText, 'font-16'].join(' ')}>
                                    {t(card.text)}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default HowWorks;
