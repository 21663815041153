import React, { useEffect, useRef } from "react";
import classes from './ExchangeTraded.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/InvestmentPage/exchange_traded.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const ExchangeTraded = () => {
    const { t } = useTranslation();
    const imageRef = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(imageRef.current, {
                scale: 0.9,
            }, {
                scale: 1,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div ref={bodyRef} className={classes.bonds}>
                <img ref={imageRef} className={classes.image} src={image} alt=''/>          
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t('investment_funds_title')}
                    </h2>
                    <p className={[classes.text_01, 'font-20-md6'].join(' ')}>
                        {t('investment_funds_text_01')}
                    </p>
                    <h4 className={[classes.subtitle, 'font-32'].join(' ')}>
                        {t('investment_funds_subtitle')}
                    </h4>
                    <p className={[classes.text_02, 'font-16'].join(' ')}>
                        {t('investment_funds_text_02')}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default ExchangeTraded;
