import React, { useEffect, useRef } from "react";
import classes from './ReadyTo.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/InvestmentPage/ready_to.png';
import Button from "../../GlobalComponents/Button/Button";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const ReadyTo = () => {
    const { t } = useTranslation();
    const imageRef = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(imageRef.current, {
                scale: 0.9,
            }, {
                scale: 1,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.readyTo}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t('investment_ready_title')}
                </h2>
                <div ref={bodyRef} className={classes.block}>
                    <div className={[classes.content, classes.content_01].join(' ')}>
                        <p className={[classes.text, 'font-20-md6'].join(' ')}>
                            {t('investment_ready_text_01')}
                        </p>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn_open_account')}
                            </Button>
                        </div>
                    </div>
                    <img ref={imageRef} className={classes.image} src={image} alt=''/>
                    <div className={[classes.content, classes.content_02].join(' ')}>
                        <p className={[classes.text, 'font-20-md6'].join(' ')}>
                            {t('investment_ready_text_02')}
                        </p>
                        <div className={classes.btn}>
                            <Button login>
                                {t('btn_login')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ReadyTo;
