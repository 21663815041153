import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import classes from "./More.module.scss";
import img1 from "../../../assets/img/main/3/1.png";
import img2 from "../../../assets/img/main/3/2.png";
import img3 from "../../../assets/img/main/3/3.png";
import img4 from "../../../assets/img/main/3/4.png";
import { useTranslation } from "react-i18next";

const More = () => {
    const { t } = useTranslation();
    gsap.registerPlugin(ScrollTrigger);
    gsap.config({
        force3D: true,
    });
    const [width, setWidth] = useState();
    const col1 = useRef(null);
    const monetRef1 = useRef(null);
    const monetRef2 = useRef(null);
    const monetRef3 = useRef(null);
    const monetRef4 = useRef(null);
    useEffect(() => {
        if (width > 1220) {

            gsap.timeline().to(monetRef1.current, {
                scrollTrigger: {
                    trigger: col1.current,
                    start: "top bottom",
                    end: "top center",
                    scrub: 3,
                },
                y: 0
            });
            gsap.timeline().to(monetRef2.current, {
                scrollTrigger: {
                    trigger: col1.current,
                    start: "top bottom",
                    end: "top center",
                    scrub: 4,
                },
                y: 0
            });
            gsap.timeline().to(monetRef3.current, {
                scrollTrigger: {
                    trigger: col1.current,
                    start: "top bottom",
                    end: "top center",
                    scrub: 5,
                },
                y: 0
            });
            gsap.timeline().to(monetRef4.current, {
                scrollTrigger: {
                    trigger: col1.current,
                    start: "top bottom",
                    end: "top center",
                    scrub: 7,
                },
                y: 0
            });

        }
    }, [width]);
    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", (event) => {
            setWidth(window.innerWidth);
        });
    }, []);
    return (
        <div className={[classes.more, "pt"].join(" ")} ref={col1}>
            <div className={[classes.moreBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.moreRelative, ""].join(" ")}>
                        <h2 className={[classes.moreTitle, "font-40"].join(" ")}>
                            {t('home_about_title')}
                        </h2>
                        <div className={[classes.moreTable, ""].join(" ")}>
                            <div className={[classes.moreTableRow, ""].join(" ")}>
                                <p className={[classes.moreTableRowLeft, "font-16"].join(" ")}>
                                    {t('home_about_row_01_col_01')}
                                </p>
                                <p className={[classes.moreTableRowRight, "font-16"].join(" ")}>$250</p>
                            </div>
                            <div className={[classes.moreTableRow, ""].join(" ")}>
                                <p className={[classes.moreTableRowLeft, "font-16"].join(" ")}>
                                    {t('home_about_row_02_col_01')}
                                </p>
                                <p className={[classes.moreTableRowRight, "font-16"].join(" ")}>
                                    {t('home_about_row_02_col_02')}
                                </p>
                            </div>
                            <div className={[classes.moreTableRow, ""].join(" ")}>
                                <p className={[classes.moreTableRowLeft, "font-16"].join(" ")}>
                                    {t('home_about_row_03_col_01')}
                                </p>
                                <p className={[classes.moreTableRowRight, "font-16"].join(" ")}>
                                    {t('home_about_row_03_col_02')}
                                </p>
                            </div>
                            <div className={[classes.moreTableRow, ""].join(" ")}>
                                <p className={[classes.moreTableRowLeft, "font-16"].join(" ")}>
                                    {t('home_about_row_04_col_01')}
                                </p>
                                <p className={[classes.moreTableRowRight, "font-16"].join(" ")}>
                                    {t('home_about_row_04_col_02')}
                                </p>
                            </div>
                            <div className={[classes.moreTableRow, ""].join(" ")}>
                                <p className={[classes.moreTableRowLeft, "font-16"].join(" ")}>
                                    {t('home_about_row_05_col_01')}
                                </p>
                                <p className={[classes.moreTableRowRight, "font-16"].join(" ")}>
                                    {t('home_about_row_05_col_02')}
                                </p>
                            </div>
                            <div className={[classes.moreTableRow, ""].join(" ")}>
                                <p className={[classes.moreTableRowLeft, "font-16"].join(" ")}>
                                    {t('home_about_row_06_col_01')}
                                </p>
                                <p className={[classes.moreTableRowRight, "font-16"].join(" ")}>
                                    {t('home_about_row_06_col_02')}
                                </p>
                            </div>
                        </div>
                        <div className={[classes.moreImg1, ""].join(" ")}
                            ref={monetRef1}
                        >
                            <img src={img1} alt="More About Atlas Finance" />
                        </div>
                        <div className={[classes.moreImg2, ""].join(" ")}
                            ref={monetRef2}
                        >
                            <img src={img2} alt="More About Atlas Finance" />
                        </div>
                        <div className={[classes.moreImg3, ""].join(" ")}
                            ref={monetRef3}
                        >
                            <img src={img3} alt="More About Atlas Finance" />
                        </div>
                        <div className={[classes.moreImg4, ""].join(" ")}
                            ref={monetRef4}
                        >
                            <img src={img4} alt="More About Atlas Finance" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default More