import logo from '../assets/img/logo.png';
import home_01 from '../assets/img/main/1/1.png';
import home_02 from '../assets/img/main/1/2.png';
import home_03 from '../assets/img/main/1/3.png';
import home_04 from '../assets/img/main/1/4.png';
import home_05 from '../assets/img/main/1/racket.png';
import home_06 from '../assets/img/main/1/mob2.png';
import inv_ch_01 from '../assets/img/InvestmentPage/Banner/bg.png';
import inv_ch_02 from '../assets/img/InvestmentPage/Banner/phones.png';
import inv_ch_03 from '../assets/img/InvestmentPage/Banner/coin_01.png';
import inv_ch_04 from '../assets/img/InvestmentPage/Banner/coin_02.png';
import inv_ch_05 from '../assets/img/InvestmentPage/Banner/coin_03.png';
import inv_ch_06 from '../assets/img/InvestmentPage/Banner/coin_04.png';
import inv_ch_07 from '../assets/img/InvestmentPage/Banner/coin_05.png';
import margin_01 from '../assets/img/MarginPage/banner.png';
import accounts_01 from '../assets/img/accounts/1/1.png';
import accounts_02 from '../assets/img/accounts/1/i1.png';
import accounts_03 from '../assets/img/accounts/1/i2.png';
import accounts_04 from '../assets/img/accounts/1/i3.png';
import accounts_05 from '../assets/img/accounts/1/i4.png';
import about_01 from '../assets/img/AboutPage/banner.png';
import about_02 from '../assets/img/AboutPage/image_01.png';

export const images = [
    logo,
    home_01,
    home_02,
    home_03,
    home_04,
    home_05,
    home_06,
    inv_ch_01,
    inv_ch_02,
    inv_ch_03,
    inv_ch_04,
    inv_ch_05,
    inv_ch_06,
    inv_ch_07,
    margin_01,
    accounts_01,
    accounts_02,
    accounts_03,
    accounts_04,
    accounts_05,
    about_01,
    about_02,
];
