import React, { useEffect, useRef } from "react";
import classes from '../OurMission/OurMission.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AboutPage/image_06.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const JoinToday = () => {
    const { t } = useTranslation();
    const imageRef = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(imageRef.current, {
                scale: 0.85,
                y: 30,
            }, {
                scale: 1,
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div ref={bodyRef} className={[classes.block, classes.blockReverse].join(' ')}>
                <img ref={imageRef} className={[classes.image, classes.image_06].join(' ')} src={image} alt=''/>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t('about_join_title')}
                    </h2>
                    <p className={[classes.text, 'font-16'].join(' ')}>
                        {t('about_join_text')}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default JoinToday;
