import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/MainComponents/Banner/Banner";
import classes from "./HomePage.module.scss";
import Columns from "../../components/MainComponents/Columns/Columns";
import More from "../../components/MainComponents/More/More";
import Award from "../../components/MainComponents/Award/Award";
import TypesBonds from "../../components/MainComponents/TypesBonds/TypesBonds";
import EasySteps from "../../components/InvestmentPage/EasySteps/EasySteps";
import Fit from "../../components/MainComponents/Fit/Fit";
const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Atlas Finance</title>
        <meta name="title" content="Trade with Confidence on Atlas Finance - Your Premier Trading Platform" />
        <meta property="og:title" content="Trade with Confidence on Atlas Finance - Your Premier Trading Platform" />
        <meta property="twitter:title" content="Trade with Confidence on Atlas Finance - Your Premier Trading Platform" />

        <meta name="description" content="Discover a seamless trading experience on Atlas Finance. Trade stocks, commodities, forex, indices, and cryptocurrencies. Start your trading journey today!" />
        <meta property="og:description" content="Discover a seamless trading experience on Atlas Finance. Trade stocks, commodities, forex, indices, and cryptocurrencies. Start your trading journey today!" />
        <meta property="twitter:description" content="Discover a seamless trading experience on Atlas Finance. Trade stocks, commodities, forex, indices, and cryptocurrencies. Start your trading journey today!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <div className={[classes.wrapper, ""].join(" ")}>
          <Banner />
          <Columns />
        </div>
        <More />
        <Award />
        <TypesBonds />
        <Fit />
        <div className="bg-dark">
          <EasySteps />
          <Footer />
        </div>
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
