import React, { useState } from 'react'
import classes from "./Banner.module.scss";
import img1 from "../../../assets/img/accounts/1/1.png";
import imgItem1 from "../../../assets/img/accounts/1/i1.png";
import imgItem2 from "../../../assets/img/accounts/1/i2.png";
import imgItem3 from "../../../assets/img/accounts/1/i3.png";
import imgItem4 from "../../../assets/img/accounts/1/i4.png";
import Button from '../../GlobalComponents/Button/Button';
import { useTranslation } from 'react-i18next';

const Banner = () => {
    const { t } = useTranslation();
    const [tabs, setTabs] = useState([
        { id: 1, img: imgItem1, title: "Standard", items: ["TRADE", "N/A", "0.7", "250$", "1 : 500", img1, "53", "6", "16", "5", "200", "50 %"], active: true },
        { id: 2, img: imgItem2, title: "Premium", items: ["TRADE", "$30 / million", "0.1", "25.000$", "1 : 500", img1, "53", "6", "16", "5", "200", "50 %"], active: false },
        { id: 3, img: imgItem3, title: "PRO", items: ["TRADE", "N/A", "0.7", "50.000 $", "1 : 500", img1, "73", "50", "18", "6", "252", "50 %"], active: false },
        { id: 4, img: imgItem4, title: "VIP", items: ["TRADE", "$30 / million", "0.1", "100.000 $", "1 : 500", img1, "73", "50", "18", "6", "252", "50 %"], active: false },
    ])
    return (
        <div className={[classes.banner, ""].join(" ")}>
            <div className={[classes.bannerBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.bannerRow, ""].join(" ")}>
                        <div className={[classes.bannerRowLeft, ""].join(" ")}>
                            <h2 className={[classes.bannerRowLeftTitle, "font-48"].join(" ")}>
                                {t('acc_types_banner_title')}
                            </h2>
                            <p className={[classes.bannerRowLeftText, "font-16"].join(" ")}>
                                {t('acc_types_banner_text_01')}
                            </p>
                            <p className={[classes.bannerRowLeftText, "font-16"].join(" ")}>
                                {t('acc_types_banner_text_02')}
                            </p>
                            <p className={[classes.bannerRowLeftText, "font-16"].join(" ")}>
                                {t('acc_types_banner_text_03')}
                            </p>
                            <div className={[classes.bannerRowLeftBtn, ""].join(" ")}>
                                <Button>
                                    {t('btn_start_trading')}
                                </Button>
                            </div>
                        </div>
                        <div className={[classes.bannerRowRight, ""].join(" ")}>
                            <div className={[classes.bannerRowRightBtns, ""].join(" ")}>
                                {tabs.map((i) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                setTabs(tabs.map((subI) => {
                                                    if (subI.id === i.id) {
                                                        return { ...subI, active: true }
                                                    } else {
                                                        return { ...subI, active: false }
                                                    }
                                                }))
                                            }}
                                            className={i.active ? [classes.bannerRowRightBtnsBtn, classes.active, "hoverElement"].join(" ") : [classes.bannerRowRightBtnsBtn, "hoverElement"].join(" ")}>
                                            <img src={i.img} alt="" />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={[classes.bannerRowRightContent, ""].join(" ")}>
                                {tabs.map((i) => {
                                    return (
                                        <div key={i.id} className={i.active ? [classes.bannerRowRightContentTab, classes.active, ""].join(" ") : [classes.bannerRowRightContentTab, ""].join(" ")}>
                                            <h2 className={[classes.bannerRowRightContentTabTitle, "font-32"].join(" ")}>
                                                {i.title}
                                            </h2>
                                            <div className={[classes.bannerRowRightContentTabGreyTexts, ""].join(" ")}>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsLeft, "font-16"].join(" ")}>
                                                    {t('acc_types_banner_row_title_01')}
                                                </p>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsRight, ""].join(" ")}>
                                                    {i.items[0]}
                                                </p>
                                            </div>
                                            <div className={[classes.bannerRowRightContentTabGreyTexts, ""].join(" ")}>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsLeft, "font-16"].join(" ")}>
                                                    {t('acc_types_banner_row_title_02')}
                                                </p>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsRight, ""].join(" ")}>
                                                    {i.items[1]}
                                                </p>
                                            </div>
                                            <div className={[classes.bannerRowRightContentTabGreyTexts, ""].join(" ")}>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsLeft, "font-16"].join(" ")}>
                                                    {t('acc_types_banner_row_title_03')}
                                                </p>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsRight, ""].join(" ")}>
                                                    {i.items[2]}
                                                </p>
                                            </div>
                                            <div className={[classes.bannerRowRightContentTabGreyTexts, ""].join(" ")}>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsLeft, "font-16"].join(" ")}>
                                                    {t('acc_types_banner_row_title_04')}
                                                </p>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsRight, ""].join(" ")}>
                                                    {i.items[3]}
                                                </p>
                                            </div>
                                            <div className={[classes.bannerRowRightContentTabGreyTexts, ""].join(" ")}>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsLeft, "font-16"].join(" ")}>
                                                    {t('acc_types_banner_row_title_05')}
                                                </p>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsRight, ""].join(" ")}>
                                                    {i.items[4]}
                                                </p>
                                            </div>
                                            <div className={[classes.bannerRowRightContentTabGreyTexts, ""].join(" ")}>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsLeft, "font-16"].join(" ")}>
                                                    {t('acc_types_banner_row_title_06')}
                                                </p>
                                                <div className={[classes.bannerRowRightContentTabGreyTextsRight, ""].join(" ")}>
                                                    <img src={i.items[5]} alt="" />
                                                </div>
                                            </div>
                                            <div className={[classes.bannerRowRightContentTabGreyTexts, ""].join(" ")}>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsLeft, "font-16"].join(" ")}>
                                                    {t('acc_types_banner_row_title_07')}
                                                </p>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsRight, ""].join(" ")}>
                                                    {i.items[6]}
                                                </p>
                                            </div>
                                            <div className={[classes.bannerRowRightContentTabGreyTexts, ""].join(" ")}>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsLeft, "font-16"].join(" ")}>
                                                    {t('acc_types_banner_row_title_08')}
                                                </p>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsRight, ""].join(" ")}>
                                                    {i.items[7]}
                                                </p>
                                            </div>
                                            <div className={[classes.bannerRowRightContentTabGreyTexts, ""].join(" ")}>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsLeft, "font-16"].join(" ")}>
                                                    {t('acc_types_banner_row_title_09')}
                                                </p>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsRight, ""].join(" ")}>
                                                    {i.items[8]}
                                                </p>
                                            </div>
                                            <div className={[classes.bannerRowRightContentTabGreyTexts, ""].join(" ")}>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsLeft, "font-16"].join(" ")}>
                                                    {t('acc_types_banner_row_title_10')}
                                                </p>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsRight, ""].join(" ")}>
                                                    {i.items[9]}
                                                </p>
                                            </div>
                                            <div className={[classes.bannerRowRightContentTabGreyTexts, ""].join(" ")}>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsLeft, "font-16"].join(" ")}>
                                                    {t('acc_types_banner_row_title_11')}
                                                </p>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsRight, ""].join(" ")}>
                                                    {i.items[10]}
                                                </p>
                                            </div>
                                            <div className={[classes.bannerRowRightContentTabGreyTexts, ""].join(" ")}>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsLeft, "font-16"].join(" ")}>
                                                    {t('acc_types_banner_row_title_12')}
                                                </p>
                                                <p className={[classes.bannerRowRightContentTabGreyTextsRight, ""].join(" ")}>
                                                    {i.items[11]}
                                                </p>
                                            </div>
                                            <div className={[classes.bannerRowRightContentTabBtn, ""].join(" ")}>
                                                <Button>
                                                    {t('btn_open_account')}
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    )
}

export default Banner