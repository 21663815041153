import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/AccountsTypes/Banner/Banner";
import TypesBonds from "../../components/AccountsTypes/TypesBonds/TypesBonds";
import Deposit from "../../components/AccountsTypes/Deposit/Deposit";

const AccountsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Account Types</title>
        <meta name="title" content="Choose the Perfect Trading Account on Atlas Finance - Explore Account Types" />
        <meta property="og:title" content="Choose the Perfect Trading Account on Atlas Finance - Explore Account Types" />
        <meta property="twitter:title" content="Choose the Perfect Trading Account on Atlas Finance - Explore Account Types" />

        <meta name="description" content="Select the ideal trading account on Atlas Finance to suit your needs. Discover basic accounts for beginners, standard accounts with added features, and premium accounts with advanced tools. Start trading today!" />
        <meta property="og:description" content="Select the ideal trading account on Atlas Finance to suit your needs. Discover basic accounts for beginners, standard accounts with added features, and premium accounts with advanced tools. Start trading today!" />
        <meta property="twitter:description" content="Select the ideal trading account on Atlas Finance to suit your needs. Discover basic accounts for beginners, standard accounts with added features, and premium accounts with advanced tools. Start trading today!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <TypesBonds />
        <Deposit />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AccountsPage;
