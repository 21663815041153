import React, { useRef, useEffect } from "react";
import classes from './EasySteps.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/InvestmentPage/easy_steps.png';
import bg from '../../../assets/img/InvestmentPage/Banner/bg.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const EasySteps = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const bodyRef = useRef(null);
    const imageRef = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            title: 'home_steps_card_01_title',
            text: 'home_steps_card_01_text',
        },
        {
            ref: cardRef_02,
            title: 'home_steps_card_02_title',
            text: 'home_steps_card_02_text',
        },
        {
            ref: cardRef_03,
            title: 'home_steps_card_03_title',
            text: 'home_steps_card_03_text',
        },
        {
            ref: cardRef_04,
            title: 'home_steps_card_04_title',
            text: 'home_steps_card_04_text',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.timeline().fromTo(cardRef_02.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_03.current, {
                    y: 100,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        start: "top+=200px bottom",
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_04.current, {
                    y: 200,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        start: "top+=200px bottom",
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(imageRef.current, {
                    scale: 1.2,
                    x: 90,
                }, {
                    scale: 1,
                    x: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        start: "top bottom",
                        end: "+=400px",
                        scrub: 3,
                    },
                });
            }, 300);
        }
    }, []);
    return (
        <section className={classes.easyStepsWrap}>
            <div className="mt container">
                <div className={classes.easySteps}>
                    <h2 className={[classes.title, 'font-32'].join(' ')}>
                        {t('home_steps_title')}
                    </h2>
                    <div ref={bodyRef} className={classes.cards}>
                        {cards.map((card, index) =>
                            <div ref={card.ref} key={index} className={classes.card}>
                                <div className={[classes.cardNum, 'font-24'].join(' ')}>
                                    {index + 1}
                                </div>
                                <h4 className={[classes.cardTitle, 'font-24'].join(' ')}>
                                    {t(card.title)}
                                </h4>
                                <p className={[classes.cardText, 'font-16'].join(' ')}>
                                    {t(card.text)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.images}>
                    <img ref={imageRef} className={classes.image} src={image} alt=''/>
                    <img className={classes.bg} src={bg} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default EasySteps;
