import React from 'react'
import classes from "./Deposit.module.scss"
import img1 from "../../../assets/img/accounts/3/1.png";
import img2 from "../../../assets/img/accounts/3/2.png";
import img3 from "../../../assets/img/accounts/3/3.png";
import img4 from "../../../assets/img/accounts/3/4.png";
import img5 from "../../../assets/img/accounts/3/5.png";
import img6 from "../../../assets/img/accounts/3/6.png";
import { useTranslation } from 'react-i18next';

const Deposit = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.deposit, "mt"].join(" ")}>
            <div className={[classes.depositBody, ""].join(" ")}>
                <div className="container">
                    <h2 className={[classes.depositTitle, "font-32"].join(" ")}>
                        {t('acc_types_dep_title')}
                    </h2>
                    <div className={[classes.depositRow, ""].join(" ")}>
                        <div className={[classes.depositRowItem, ""].join(" ")}>
                            <img src={img1} alt="Deposit Funds Using any of the Available Payment Methods" />
                        </div>
                        <div className={[classes.depositRowItem, ""].join(" ")}>
                            <img src={img2} alt="Deposit Funds Using any of the Available Payment Methods" />
                        </div>
                        <div className={[classes.depositRowItem, ""].join(" ")}>
                            <img src={img3} alt="Deposit Funds Using any of the Available Payment Methods" />
                        </div>
                        <div className={[classes.depositRowItem, ""].join(" ")}>
                            <img src={img4} alt="Deposit Funds Using any of the Available Payment Methods" />
                        </div>
                        <div className={[classes.depositRowItem, ""].join(" ")}>
                            <img src={img5} alt="Deposit Funds Using any of the Available Payment Methods" />
                        </div>
                        <div className={[classes.depositRowItem, ""].join(" ")}>
                            <img src={img6} alt="Deposit Funds Using any of the Available Payment Methods" />
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Deposit