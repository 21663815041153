import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/AboutPage/Banner/Banner";
import OurMission from "../../components/AboutPage/OurMission/OurMission";
import WideRange from "../../components/AboutPage/WideRange/WideRange";
import SecurityTrust from "../../components/AboutPage/SecurityTrust/SecurityTrust";
import CuttingEdge from "../../components/AboutPage/CuttingEdge/CuttingEdge";
import Flexible from "../../components/AboutPage/Flexible/Flexible";
import JoinToday from "../../components/AboutPage/JoinToday/JoinToday";
import OurValues from "../../components/AboutPage/OurValues/OurValues";

const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>About Us</title>
        <meta name="title" content="About Atlas Finance - Empowering Traders Worldwide"/>
        <meta property="og:title" content="About Atlas Finance - Empowering Traders Worldwide"/>
        <meta property="twitter:title" content="About Atlas Finance - Empowering Traders Worldwide"/>

        <meta name="description" content="Learn more about Atlas Finance, a leading trading platform empowering traders globally. Discover our mission, cutting-edge technology, wide range of instruments, and commitment to customer support." />
        <meta property="og:description" content="Learn more about Atlas Finance, a leading trading platform empowering traders globally. Discover our mission, cutting-edge technology, wide range of instruments, and commitment to customer support." />
        <meta property="twitter:description" content="Learn more about Atlas Finance, a leading trading platform empowering traders globally. Discover our mission, cutting-edge technology, wide range of instruments, and commitment to customer support." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <OurMission />
        <CuttingEdge />
        <WideRange />
        <Flexible />
        <SecurityTrust />
        <JoinToday />
        <OurValues />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;
