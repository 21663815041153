import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import classes from "./Columns.module.scss";
import img1 from "../../../assets/img/main/2/1.png";
import img2 from "../../../assets/img/main/2/2.png";
import img3 from "../../../assets/img/main/2/3.png";
import img4 from "../../../assets/img/main/2/4.png";
import imgBg from "../../../assets/img/main/2/bg.png";
import { Trans, useTranslation } from "react-i18next";

const Columns = () => {
    const { t } = useTranslation();
    gsap.registerPlugin(ScrollTrigger);
    gsap.config({
        force3D: true,
    });
    const [width, setWidth] = useState();
    const col1 = useRef(null);
    const monetRef1 = useRef(null);
    const monetRef2 = useRef(null);
    const monetRef3 = useRef(null);
    const monetRef4 = useRef(null);
    const monetRef5 = useRef(null);
    useEffect(() => {
        if (width > 1220) {

            gsap.timeline().to(monetRef1.current, {
                scrollTrigger: {
                    trigger: col1.current,
                    start: "top bottom",
                    end: "top center",
                    scrub: 1,
                },
                y: 0
            });
            gsap.timeline().to(monetRef2.current, {
                scrollTrigger: {
                    trigger: col1.current,
                    start: "top bottom",
                    end: "top center",
                    scrub: 3,
                },
                y: 0
            });
            gsap.timeline().to(monetRef3.current, {
                scrollTrigger: {
                    trigger: col1.current,
                    start: "top bottom",
                    end: "top center",
                    scrub: 5,
                },
                y: 0
            });
            gsap.timeline().to(monetRef4.current, {
                scrollTrigger: {
                    trigger: col1.current,
                    start: "top bottom",
                    end: "top center",
                    scrub: 7,
                },
                y: 0
            });
            gsap.timeline().to(monetRef5.current, {
                scrollTrigger: {
                    trigger: col1.current,
                    start: "top bottom",
                    end: "top center",
                    scrub: 4,
                },
                y: 0,
                scale: 1
            });
        }
    }, [width]);
    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
    }, []);
    return (
        <div className={[classes.block, "pt"].join(" ")} ref={col1}>
            <div className={[classes.blockImg, ""].join(" ")} ref={monetRef5}>
                <img src={imgBg} alt="Atlas Finance - Unleash Your Trading Potential" />
            </div>
            <div className={[classes.blockBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.blockRow, ""].join(" ")}>
                        <div className={[classes.blockRowColumn, ""].join(" ")} ref={monetRef1}>
                            <div className={[classes.blockRowColumnItem, ""].join(" ")}>
                                <div className={[classes.blockRowColumnItemImg, ""].join(" ")}>
                                    <img src={img1} alt="Atlas Finance - Unleash Your Trading Potential" />
                                </div>
                                <h2 className={[classes.blockRowColumnItemTitle, "font-20-md6"].join(" ")}>
                                    <Trans>
                                        {t('home_underbanner_card_01_title')}
                                    </Trans>
                                </h2>
                                <p className={[classes.blockRowColumnItemText, "font-16"].join(" ")}>
                                    {t('home_underbanner_card_01_text')}
                                </p>
                            </div>
                        </div>
                        <div className={[classes.blockRowColumn, ""].join(" ")} ref={monetRef2}>
                            <div className={[classes.blockRowColumnItem, ""].join(" ")}>
                                <div className={[classes.blockRowColumnItemImg, ""].join(" ")}>
                                    <img src={img2} alt="Atlas Finance - Unleash Your Trading Potential" />
                                </div>
                                <h2 className={[classes.blockRowColumnItemTitle, "font-20-md6"].join(" ")}>
                                    <Trans>
                                        {t('home_underbanner_card_02_title')}
                                    </Trans>
                                </h2>
                                <p className={[classes.blockRowColumnItemText, "font-16"].join(" ")}>
                                    {t('home_underbanner_card_02_text')}
                                </p>
                            </div>
                        </div>
                        <div className={[classes.blockRowColumn, ""].join(" ")}
                            ref={monetRef3}
                        >
                            <div className={[classes.blockRowColumnItem, ""].join(" ")}>
                                <div className={[classes.blockRowColumnItemImg, ""].join(" ")}>
                                    <img src={img3} alt="Atlas Finance - Unleash Your Trading Potential" />
                                </div>
                                <h2 className={[classes.blockRowColumnItemTitle, "font-20-md6"].join(" ")}>
                                    <Trans>
                                        {t('home_underbanner_card_03_title')}
                                    </Trans>
                                </h2>
                                <p className={[classes.blockRowColumnItemText, "font-16"].join(" ")}>
                                    {t('home_underbanner_card_03_text')}
                                </p>
                            </div>
                        </div>
                        <div className={[classes.blockRowColumn, ""].join(" ")} ref={monetRef4}>
                            <div className={[classes.blockRowColumnItem, ""].join(" ")}>
                                <div className={[classes.blockRowColumnItemImg, ""].join(" ")}>
                                    <img src={img4} alt="Atlas Finance - Unleash Your Trading Potential" />
                                </div>
                                <h2 className={[classes.blockRowColumnItemTitle, "font-20-md6"].join(" ")}>
                                    <Trans>
                                        {t('home_underbanner_card_04_title')}
                                    </Trans>
                                </h2>
                                <p className={[classes.blockRowColumnItemText, "font-16"].join(" ")}>
                                    {t('home_underbanner_card_04_text')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Columns