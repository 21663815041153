import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AboutPage/banner.png';
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const imageRef = useRef(null);
    const contentRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.to(bodyRef.current, {
                autoAlpha: 1,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(contentRef.current, {
                scale: 0.8,
                y: 50,
                x: -180,
            }, {
                scale: 1,
                y: 0,
                x: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(imageRef.current, {
                scale: 0.8,
                y: 60,
            }, {
                scale: 1,
                y: 0,
                delay: 0.5,
                duration: 1,
            });
        }
    }, []);
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div ref={bodyRef} className={classes.banner}>
                    <div ref={contentRef} className={classes.content}>
                        <h2 className={[classes.title, 'font-40'].join(' ')}>
                            {t('about_banner_title')}
                        </h2>
                        <p className={[classes.text, 'font-16'].join(' ')}>
                            {t('about_banner_text')}
                        </p>
                    </div>
                    <img ref={imageRef} className={classes.image} src={image} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Banner;
