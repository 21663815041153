import React, { useState } from 'react'
import classes from "./Fit.module.scss";
import img1 from "../../../assets/img/main/6/1.png";
import img2 from "../../../assets/img/main/6/2.png";
import { useTranslation } from 'react-i18next';

const Fit = () => {
    const { t } = useTranslation();
    const [counter, setCounter] = useState(1)
    return (
        <div className={[classes.fit, "pt"].join(" ")}>
            <div className={[classes.fitBody, ""].join(" ")}>
                <div className="container">
                    <h2 className={[classes.fitTitle, "font-32"].join(" ")}>
                        {t('home_fit_title')}
                    </h2>
                    <div className={[classes.fitTabs, ""].join(" ")}>
                        <div className={counter === 1 ? [classes.fitTabsTab, classes.active, "font-20-md4 hoverElement"].join(" ") : [classes.fitTabsTab, "font-20-md4 hoverElement"].join(" ")}
                            onClick={() => {
                                setCounter(1)
                            }}
                        >
                            {t('home_fit_btn_01')}
                        </div>
                        <div className={counter === 2 ? [classes.fitTabsTab, classes.active, "font-20-md4 hoverElement"].join(" ") : [classes.fitTabsTab, "font-20-md4 hoverElement"].join(" ")}
                            onClick={() => {
                                setCounter(2)
                            }}
                        >
                            {t('home_fit_btn_02')}
                        </div>
                    </div>
                    <div className={counter === 1 ? [classes.fitRow, classes.active, ""].join(" ") : [classes.fitRow, ""].join(" ")}>
                        <div className={[classes.fitRowLeft, ""].join(" ")}>
                            <h3 className={[classes.fitRowLeftTitle, "font-20-md6"].join(" ")}>
                                {t('home_fit_btn_01')}
                            </h3>
                            <p className={[classes.fitRowLeftText, "font-16"].join(" ")}>
                                {t('home_fit_text_01')}
                            </p>
                        </div>
                        <div className={[classes.fitRowRight, ""].join(" ")}>
                            <img src={img1} alt="Desktop" />
                        </div>
                    </div>
                    <div className={counter === 2 ? [classes.fitRow, classes.active, ""].join(" ") : [classes.fitRow, ""].join(" ")}>
                        <div className={[classes.fitRowLeft, ""].join(" ")}>
                            <h3 className={[classes.fitRowLeftTitle, "font-20-md6"].join(" ")}>
                                {t('home_fit_btn_02')}
                            </h3>
                            <p className={[classes.fitRowLeftText, "font-16"].join(" ")}>
                                {t('home_fit_text_02')}
                            </p>
                        </div>
                        <div className={[classes.fitRowRight, ""].join(" ")}>
                            <img src={img2} alt="Browser" />
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Fit