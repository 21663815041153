import React, { useEffect, useRef } from "react";
import classes from './TypesBonds.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/main/5/1.png';
import icon_02 from '../../../assets/img/main/5/2.png';
import icon_03 from '../../../assets/img/main/5/3.png';
import icon_04 from '../../../assets/img/main/5/4.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const TypesBonds = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            icon: icon_01,
            title: 'home_key_card_01_title',
            text: 'home_key_card_01_text',
        },
        {
            ref: cardRef_02,
            icon: icon_02,
            title: 'home_key_card_02_title',
            text: 'home_key_card_02_text',
        },
        {
            ref: cardRef_03,
            icon: icon_03,
            title: 'home_key_card_03_title',
            text: 'home_key_card_03_text',
        },
        {
            ref: cardRef_04,
            icon: icon_04,
            title: 'home_key_card_04_title',
            text: 'home_key_card_04_text',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 150,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=200px bottom",
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_04.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=200px bottom",
                    end: "+=300px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.typesBonds}>
                <h2 className={[classes.title, 'font-32'].join(' ')}>
                    {t('home_key_title')}
                </h2>
                <div ref={bodyRef} className={classes.cards}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} key={index} className={classes.card}>
                            <img className={classes.cardIcon} src={card.icon} alt='' />
                            <h4 className={[classes.cardTitle, 'font-20-md6'].join(' ')}>
                                {t(card.title)}
                            </h4>
                            <p className={[classes.cardText, 'font-16'].join(' ')}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
                <div className={classes.swiperWrap}>
                    <Swiper
                        modules={[Pagination]}
                        className="homeTypesBondsSwiper"
                        spaceBetween={20}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            700: {
                                slidesPerView: 2,
                            },
                            1220: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {cards.map((card, index) =>
                            <SwiperSlide key={index} className="homeTypesBondsSwiperSlide">
                                <div className={classes.card}>
                                    <img className={classes.cardIcon} src={card.icon} alt='' />
                                    <h4 className={[classes.cardTitle, 'font-20-md6'].join(' ')}>
                                        {t(card.title)}
                                    </h4>
                                    <p className={[classes.cardText, 'font-16'].join(' ')}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default TypesBonds;
