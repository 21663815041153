import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/MarginPage/banner.png';
import { gsap } from "gsap";
import Button from "../../GlobalComponents/Button/Button";
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const contentRef = useRef(null);
    const imageRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.to(bodyRef.current, {
                autoAlpha: 1,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(contentRef.current, {
                scale: 0.8,
                y: 50,
                x: -180,
            }, {
                scale: 1,
                y: 0,
                x: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(imageRef.current, {
                scale: 0.8,
                y: 60,
            }, {
                scale: 1,
                y: 0,
                delay: 0.5,
                duration: 1,
            });
        }
    }, []);
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div ref={bodyRef} className={classes.banner}>
                    <div ref={contentRef} className={classes.content}>
                        <h1 className={[classes.title, 'font-48'].join(' ')}>
                            {t('margin_banner_title')}
                        </h1>
                        <h2 className={[classes.subtitle, 'font-36'].join(' ')}>
                            {t('margin_banner_subtitle')}
                        </h2>
                        <div className={[classes.text, 'font-16 text-flex'].join(' ')}>
                            <p>
                                {t('margin_banner_text_01')}
                            </p>
                            <p>
                                {t('margin_banner_text_02')}
                            </p>
                            <p>
                                {t('margin_banner_text_03')}
                            </p>
                            <p>
                                {t('margin_banner_text_04')}
                            </p>
                        </div>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn_start_trading')}
                            </Button>
                        </div>
                    </div>
                    <img ref={imageRef} className={classes.image} src={image} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Banner;
