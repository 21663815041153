import React, { useState } from "react";
import classes from './Faq.module.scss';
import { useTranslation } from "react-i18next";

const tabs = [
    {
        title: 'margin_faqs_01_title',
        text: 'margin_faqs_01_text',
    },
    {
        title: 'margin_faqs_02_title',
        text: 'margin_faqs_02_text',
    },
    {
        title: 'margin_faqs_03_title',
        text: 'margin_faqs_03_text',
    },
    {
        title: 'margin_faqs_04_title',
        text: 'margin_faqs_04_text',
    },
    {
        title: 'margin_faqs_05_title',
        text: 'margin_faqs_05_text',
    },
];

const Faq = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(0);
    const setTab = (value) => {
        if (currentTab === value) {
            setCurrentTab(-1);
            return;
        }
        setCurrentTab(value);
        return;
    };
    return (
        <section className="mt container">
            <div className={classes.faq}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t('margin_faqs_title')}
                </h2>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <div 
                            className={`${classes.tab} ${currentTab === index && classes.tabActive} no-select hoverElement`}
                            key={index}
                            onClick={() => setTab(index)}
                        >
                            <div className={classes.tabHeader}>
                                <p className={[classes.tabTitle, 'font-16'].join(' ')}>
                                    {t(tab.title)}
                                </p>
                                <div className={classes.tabStatus}></div>
                            </div>
                            <div className={classes.tabContent}>
                                <p className="font-16">
                                    {t(tab.text)}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Faq;
