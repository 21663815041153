import React, { useEffect, useRef } from "react";
import classes from './OurMission.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AboutPage/image_01.png';
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});

const OurMission = () => {
    const { t } = useTranslation();
    const imageRef = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(imageRef.current, {
                scale: 0.8,
                y: 50,
            }, {
                scale: 1,
                y: 0,
                delay: 0.5,
                duration: 1,
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div ref={bodyRef} className={classes.block}>
                <img ref={imageRef} className={[classes.image, classes.image_01].join(' ')} src={image} alt=''/>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t('about_mission_title')}
                    </h2>
                    <p className={[classes.text, 'font-16'].join(' ')}>
                        {t('about_mission_text')}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default OurMission;
