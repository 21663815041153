import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import phones from '../../../assets/img/InvestmentPage/Banner/phones.png';
import bg from '../../../assets/img/InvestmentPage/Banner/bg.png';
import coin_01 from '../../../assets/img/InvestmentPage/Banner/coin_01.png';
import coin_02 from '../../../assets/img/InvestmentPage/Banner/coin_02.png';
import coin_03 from '../../../assets/img/InvestmentPage/Banner/coin_03.png';
import coin_04 from '../../../assets/img/InvestmentPage/Banner/coin_04.png';
import coin_05 from '../../../assets/img/InvestmentPage/Banner/coin_05.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const contentRef = useRef(null);
    const phonesRef = useRef(null);
    const bgRef = useRef(null);
    const coinWrapRef_01 = useRef(null);
    const coinWrapRef_02 = useRef(null);
    const coinWrapRef_03 = useRef(null);
    const coinWrapRef_04 = useRef(null);
    const coinWrapRef_05 = useRef(null);
    const coinRef_01 = useRef(null);
    const coinRef_02 = useRef(null);
    const coinRef_03 = useRef(null);
    const coinRef_04 = useRef(null);
    const coinRef_05 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.to(bodyRef.current, {
                autoAlpha: 1,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(contentRef.current, {
                scale: 0.8,
                y: 50,
                x: -180,
            }, {
                scale: 1,
                y: 0,
                x: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(phonesRef.current, {
                scale: 1.1,
            }, {
                scale: 1,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(bgRef.current, {
                scale: 1.05,
                x: 70,
            }, {
                scale: 1,
                x: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(coinWrapRef_01.current, {
                scale: 1.1,
                x: -60,
                y: 30,
            }, {
                scale: 1,
                x: 0,
                y: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(coinWrapRef_02.current, {
                scale: 1.1,
                x: -60,
                y: -40,
            }, {
                scale: 1,
                x: 0,
                y: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(coinWrapRef_03.current, {
                scale: 1.1,
                x: 20,
                y: -70,
            }, {
                scale: 1,
                x: 0,
                y: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(coinWrapRef_04.current, {
                scale: 1.1,
                x: 60,
                y: -40,
            }, {
                scale: 1,
                x: 0,
                y: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(coinWrapRef_05.current, {
                scale: 1.1,
                x: 80,
                y: 30,
            }, {
                scale: 1,
                x: 0,
                y: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.timeline().to(coinRef_01.current, {
                y: 100,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=150px top",
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().to(coinRef_02.current, {
                y: 150,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=150px top",
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().to(coinRef_03.current, {
                y: 120,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=150px top",
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().to(coinRef_04.current, {
                y: 110,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=150px top",
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().to(coinRef_05.current, {
                y: 50,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top+=150px top",
                    end: "+=400px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div ref={bodyRef} className={classes.banner}>
                    <div ref={contentRef} className={classes.content}>
                        <h1 className={[classes.title, 'font-48'].join(' ')}>
                            {t('investment_banner_title')}
                        </h1>
                        <h2 className={[classes.subtitle, 'font-36'].join(' ')}>
                            {t('investment_banner_subtitle')}
                        </h2>
                        <div className={[classes.text, 'font-16 text-flex'].join(' ')}>
                            <p>
                                {t('investment_banner_text_01')}
                            </p>
                            <p>
                                {t('investment_banner_text_02')}
                            </p>
                            <p>
                                {t('investment_banner_text_03')}
                            </p>
                        </div>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn_start_trading')}
                            </Button>
                        </div>
                    </div>
                    <div className={classes.images}>
                        <img ref={phonesRef} className={classes.phones} src={phones} alt=''/>
                        <img ref={bgRef} className={classes.bg} src={bg} alt=''/>
                        <div ref={coinWrapRef_01} className={[classes.coinWrap_01, classes.coinWrap].join(' ')}>
                            <div ref={coinRef_01} className={classes.coin}>
                                <img src={coin_01} alt=''/>
                            </div>
                        </div>
                        <div ref={coinWrapRef_02} className={[classes.coinWrap_02, classes.coinWrap].join(' ')}>
                            <div ref={coinRef_02} className={classes.coin}>
                                <img src={coin_02} alt=''/>
                            </div>
                        </div>
                        <div ref={coinWrapRef_03} className={[classes.coinWrap_03, classes.coinWrap].join(' ')}>
                            <div ref={coinRef_03} className={classes.coin}>
                                <img src={coin_03} alt=''/>
                            </div>
                        </div>
                        <div ref={coinWrapRef_04} className={[classes.coinWrap_04, classes.coinWrap].join(' ')}>
                            <div ref={coinRef_04} className={classes.coin}>
                                <img src={coin_04} alt=''/>
                            </div>
                        </div>
                        <div ref={coinWrapRef_05} className={[classes.coinWrap_05, classes.coinWrap].join(' ')}>
                            <div ref={coinRef_05} className={classes.coin}>
                                <img src={coin_05} alt=''/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
